// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInAnonymously } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD4u8kz2u2HxwEX1PhkQv7bjM33qL1YkkY",
  authDomain: "eventagram.jp",
  projectId: "eventagram-a",
  storageBucket: "eventagram-a.firebasestorage.app",
  messagingSenderId: "700057427079",
  appId: "1:700057427079:web:497786aea244e405f7ec22",
  measurementId: "G-F173LHS6HH"
};

export const getEventsByDate = async (selectedDate) => {
  const eventsCollection = collection(db, 'events');
  const q = query(eventsCollection, where('eventDate', '==', selectedDate));

  const querySnapshot = await getDocs(q);
  let events = [];
  querySnapshot.forEach((doc) => {
    events.push({ id: doc.id, ...doc.data() });
  });

  return events;
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

// セッション永続性の設定
setPersistence(auth, browserLocalPersistence);

export { app };
export { db };
export { auth, GoogleAuthProvider, signOut, signInWithPopup, onAuthStateChanged, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInAnonymously };